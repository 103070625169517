import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=6015a17f&"
import script from "./Navbar.vue?vue&type=script&lang=js&"
export * from "./Navbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavLink: require('/vercel/path0/src/components/NavLink.vue').default})
