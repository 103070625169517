import { render, staticRenderFns } from "./default.vue?vue&type=template&id=c09c75b0&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=c09c75b0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseNavbar: require('/vercel/path0/src/components/base/Navbar.vue').default,BaseFooter: require('/vercel/path0/src/components/base/Footer.vue').default})
