
export default {
  name: 'base-navbar',
  data() {
    return {
      open: false,
      dropdownNavbar: false,
    };
  },
};
